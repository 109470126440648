/************ Footer Css ************/
.footer {
    padding: 0 0 50px 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
  
.footer img {
    width: 26%;
}


.footer p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 20px;
}
  
.footer-logo {
    margin-top: 3rem;
}
  
.social-icon.footer-icon {
    display: flex;
    justify-content: flex-end;
}